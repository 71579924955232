body {
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
}

.wrapper {
  overflow: hidden;
  width: 100%;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

ul li {
  list-style-type: none;
}
.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}
.justify-space-between {
  justify-content: space-between;
}

.container {
  max-width: 1160px;
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;
}
.justify-space-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
* {
  box-sizing: border-box;
}
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 10px;
  color: #0c518a;
  font-weight: 700;
}
p {
  color: #8a909a;
  font-size: 16px;
  margin: 0 0 15px;
  line-height: 24px;
}
.orange-btn {
  background-color: #ff6128;
}
/* Header */
.tp-left p {
  font-size: 16px;
  color: #000;
  margin: 0;
}

.tp-left p a {
  color: #0c518a;
  text-decoration: none;
}

.lgn-btn li a {
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  color: #0c518a;
}

ul.lgn-btn {
  margin: 0;
  padding: 0;
}

.lgn-btn li {
  margin: 0 10px;
}

.tp-social-link {
  margin: 0 0 0 20px;
  padding: 0 0 0 10px;
  border-left: 1px solid #d9d9d9;
}

.tp-social-link li a {
  display: block;
  margin: 0 7px;
}

.tp-social-link li a img {
  width: 19px;
  display: block;
}

.top-header {
  padding: 15px 15px;
}

.tp-social-link {
  margin: 0 0 0 20px;
  padding: 0 0 0 10px;
  border-left: 1px solid #d9d9d9;
}

.tp-social-link li a {
  display: block;
  margin: 0 7px;
}

.tp-social-link li a img {
  width: 19px;
  display: block;
}
.middle-header {
  background-color: #0c518a;
  padding: 12px 15px;
}

ul.main-menu {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.main-menu li a {
  color: #fff;
  text-decoration: none;
  font-weight: 700;
}

.main-menu li {
  margin: 0 0 0 40px;
}
.logo img {
  width: 100%;
  max-width: 163px;
  display: block;
}
.toggle-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: none;
}

.toggle-btn svg {
  width: 35px;
  height: 35px;
}

.toggle-btn svg path {
  fill: #fff;
}
/* End Header */

/* Hero section */
.hero-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  padding: 9% 0 15%;
}

.hero-inner {
  text-align: center;
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
}

.hero-inner h1 {
  font-size: 50px;
  color: #fff;
  margin: 0 0 20px;
}

.hero-inner p {
  color: #fff;
  line-height: 28px;
  margin: 0 0 30px;
}

.btn {
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  padding: 15px 44px;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  transition: all 0.3s;
}
.btn img {
  width: 25px;
  display: inline-block;
  vertical-align: bottom;
  margin-left: 10px;
}
.blue-btn {
  background-color: #0c518a;
}
/* End Hero section */

/* Automation section */
.automate-section {
  padding: 50px 0 90px;
}
.col-md-6 {
  width: 50%;
  padding: 0 15px;
}

.auto-left h2 {
  font-size: 40px;
}

.button-grups {
  display: flex;
  margin: 35px 0 0;
  flex-wrap: wrap;
}

.button-grups .btn {
  margin: 0 20px 5px 0px;
}

.auto-form {
  background-color: #0c518a;
  max-width: 420px;
  margin-left: auto;
  border-radius: 20px;
  padding: 40px 50px 50px;
  text-align: center;
}

.auto-form h3 {
  color: #fff;
  font-size: 30px;
  margin: 0 0 10px;
}

.auto-form p {
  color: #fff;
}

.agenda-info {
  background-color: #0c518a;
  margin-left: auto;
  border-radius: 20px;
  padding: 40px 50px 50px;
  text-align: center;
}

.agenda-info h3 {
  color: #fff;
  font-size: 30px;
  margin: 0 0 10px;
}

.agenda-info p {
  color: #fff;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.our-history h2 {
  color: #fff;
  font-size: 27px;
}

.our-history li {
  color: #fff;
}


.ct-field {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #ddd;
  font-size: 14px;
  color: #9a9a9a;
  font-family: "Nunito Sans", sans-serif;
  border-radius: 5px;
}

.form-field {
  margin: 0 0 10px;
}

.sbt-btn {
  width: 100%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  font-family: "Nunito Sans", sans-serif;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.sbt-btn img {
  margin-left: 8px;
  width: 25px;
  display: inline-block;
}

.form-field:last-child {
  margin-bottom: 0;
}

.ct-field:focus {
  outline: none;
  box-shadow: none;
}
/* End Automation section */

/* Landing-slider section */
.owl-dots {
  text-align: center;
  padding-top: 15px;
}
.owl-dots button.owl-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background: #d9d9d9 !important;
  margin: 0 10px;
}
.owl-dots button.owl-dot.active {
  background-color: #ff6128 !important;
}
.owl-dots button.owl-dot:focus {
  outline: none;
}
.owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.38) !important;
}
.owl-nav button:focus {
  outline: none;
}

.landing-carousel .item {
  padding: 35% 0 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.landing-bx {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px 40px;
  max-width: 690px;
  width: 100%;
  border-bottom: 5px solid #ff6128;
}

.landing-bx h3 {
  margin: 0;
  font-size: 30px;
}

/* End Landing-slider section */

/* all service section */
.head-cmn {
  text-align: center;
}

.head-cmn h2 {
  font-size: 48px;
}

.cmn-brd span {
  height: 6px;
  border-radius: 50px;
  background-color: #ff6128;
  display: inline-block;
  margin: 0 5px;
}

.cmn-brd {
  text-align: center;
  line-height: normal;
  display: flex;
  justify-content: center;
  margin: 25px 0 0;
}

.fst-brd {
  width: 16px;
}

.secend-brd {
  width: 46px;
}

.head-cmn p {
  margin: 20px auto;
  line-height: 27px;
  max-width: 720px;
}
.cmn-padd {
  padding: 90px 0;
}

.srv-bx-img img {
  max-width: 81px;
  margin: 0 auto;
}

.srv-bx-img {
  margin: 0 0 10px;
}

.srv-bx h4 {
  color: #0e0e46;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin: 0;
}
.srv-bx {
  box-shadow: 0 0 20px 1px rgb(189 189 189 / 24%);
  padding: 20px 10px;
  border-radius: 10px;
  height: 100%;
  border: 1px solid transparent;
  transition: all 0.3s;
}
.srv-bx:hover {
  border-color: #ff6128;
}
.all-service-carousel .owl-stage-outer {
  padding: 20px 10px;
}

.all-service-carousel .owl-stage-outer .owl-stage {
  display: flex;
  flex-wrap: wrap;
}

.all-service-carousel .item {
  height: 100%;
}
.text-center {
  text-align: center;
}

.get-more-bx {
  margin-top: 35px;
}
/* End all service section */

/* section one */
.top-shape img {
  width: 100%;
  display: block;
}

.bottom-shape img {
  width: 100%;
  display: block;
}
.dark-blue-bg {
  background-color: #0c518a;
}

.color-white {
  color: #fff;
}

.cmn-left h3 {
  font-size: 23px;
  line-height: 31px;
}

ul.cmn-list {
  line-height: normal;
  margin: 0px 0 0px;
  padding: 0;
}
.cmn-left .cmn-brd {
  margin: 25px 0;
}
.cmn-list li {
  font-size: 18px;
  padding-left: 32px;
  position: relative;
  line-height: normal;
  margin: 7px 0;
}

.cmn-list li::before {
  content: "";
  background-image: url("../../public/images/double-check.svg");
  width: 21px;
  height: 21px;
  position: absolute;
  left: 0;
  background-repeat: no-repeat;
}

.justify-start {
  justify-content: flex-start;
}

.cmn-right img {
  width: 100%;
}

.cmn-left p strong {
  color: #0c518a;
}
/* End section one */

/* section three */
.big-padd {
  padding: 12% 0;
}

.btn svg {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 10px;
}
.dark-orange-bg {
  background-image: linear-gradient(137deg, #ff6128 0.52%, #f4711e 100%);
}

.color-white p,
.color-white p strong {
  color: #fff;
}

.white-btn {
  background-color: #fff;
  color: #0c518a;
}
.tp-wave::before {
  background-image: url("../../public/images/orange-wave-top.svg");
  content: "";
  position: absolute;
  height: 156px;
  width: 100%;
  top: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.section-three {
  position: relative;
}
.white-btn svg path {
  fill: #0c518a;
}
.btm-wave::after {
  content: "";
  position: absolute;
  height: 130px;
  width: 100%;
  bottom: -2px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../public/images/orange-wave-bottom.svg");
}
.cmn-left a.btn {
  margin-top: 25px;
}
.pd-right {
  padding-right: 30px;
}
/* End section three */

/* section four */
.col-md-7 {
  width: 55%;
  padding: 0 15px;
}

.col-md-5 {
  width: 45%;
  padding: 0 15px;
}
.pd-left {
  padding-left: 20px;
}
/* End section four */

/* Counter section */
.col-md-3 {
  width: 25%;
  padding: 0 15px;
}

.counter-bx {
  background-color: #fff;
  text-align: center;
  border-radius: 20px;
  padding: 30px 20px;
}

.counter-txt h3 {
  color: #ff6128;
  font-size: 48px;
  line-height: normal;
  margin: 0 0 5px;
}

.counter-img img {
  height: 78px;
  display: block;
  margin: 0 auto;
}

.counter-txt {
  margin: 15px 0 0;
}

.counter-txt p {
  font-size: 18px;
  line-height: 21px;
  margin: 0;
}
/* End Counter section */

/* Testimonial section */
.testimonial-main {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgb(189 189 189 / 24%);
}

.testimonial-vdo {
  width: 45%;
}

.testimonial-content {
  width: 55%;
  padding-left: 30px;
}

.testimonial-vdo img {
  display: block;
  width: 100%;
  height: 348px;
  max-height: 348px;
  object-fit: cover;
  object-position: top center;
  border-radius: 10px;
}

.tst-prf-img {
  width: 72px;
  overflow: hidden;
}

.tst-ct {
  width: calc(100% - 72px);
  padding-left: 20px;
}

.tst-ct img {
  width: 95px !important;
}

.tst-ct h4 {
  margin: 0;
  font-size: 20px;
  color: #0e0e46;
}

.testimonial-content p {
  line-height: 28px;
}

.tst-prf {
  margin-bottom: 20px;
}

.tst-prf-img img {
  max-width: 100%;
  height: 72px;
  border-radius: 50%;
  display: block;
  object-fit: cover;
}

.testimonial-section {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.testimonial-carousel {
  margin-top: 30px !important;
}

.owl-nav button {
  position: unset;
  transform: unset;
}

.owl-nav {
  text-align: center;
  margin-top: 40px;
}

.owl-nav button {
  width: 44px;
  height: 44px;
  background-color: #0c518a !important;
  border-radius: 50px;
  color: #fff !important;
  margin: 0 8px;
}

.owl-nav button span {
  font-size: 0;
  font-family: FontAwesome;
}
.owl-next span::before {
  content: "\f061";
  font-family: FontAwesome;
  font-size: 16px;
}

.owl-prev span::before {
  content: "\f060";
  font-family: FontAwesome;
  font-size: 16px;
}
/* End Testimonial section */

/* Sponsor section */
.sponser-head h2 {
  font-size: 32px;
}

.sponser-head .cmn-brd {
  margin-top: 15px;
}

.sponser-wd {
  padding: 0 15px;
}

.sponser-main .row-flex {
  justify-content: space-between;
  align-items: center;
}

.sponser-main {
  margin-top: 50px;
}
/* End Sponsor section */

/* Newsletter section */
.newsletter-main {
  padding: 60px 50px;
  border-radius: 20px;
}

.news-left h3 {
  color: #fff;
  font-size: 32px;
}

.news-left p {
  color: #fff;
  font-size: 18px;
}

.nws-field {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 10px;
  padding: 14px 18px;
}

.sbc-btn {
  background-color: #f66d20;
  color: #fff;
  border: none;
  font-size: 15px;
  padding: 14px 18px;
  border-radius: 10px;
  font-family: "Nunito Sans", sans-serif;
  cursor: pointer;
}

.nws-field input {
  width: calc(100% - 104px);
  border: none;
  padding: 10px 10px;
  font-family: "Nunito Sans", sans-serif;
  color: #8a909a;
  font-size: 14px;
}
/* End Newsletter section */

/* Footer */
.footer {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  padding: 50px 0 50px;
  margin-top: 50px;
}

.ftr-cmn-wd {
  width: 16.5%;
  padding: 0 15px;
}
.ftr-one-wd {
  width: 34%;
  padding-right: 20px;
  padding-left: 15px;
}
.app-logo img {
  display: block;
  margin: 0 10px 0 0px;
}

.app-logo {
  display: flex;
  flex-wrap: wrap;
}
.ftr-bx p {
  color: #fff;
}

.ftr-social h4 {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
}

.ftr-social ul {
  margin: 0;
  padding: 0;
}

.ftr-social ul li a {
  background-color: #fff;
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 50px;
  line-height: 40px;
  text-align: center;
  color: #0e0e46;
  font-size: 18px;
}

.ftr-social ul li {
  margin-right: 10px;
}

.ftr-social {
  margin-top: 20px;
}

.ftr-bx h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}

.ft-list-locations {
  margin: 0;
  padding: 0;
}

.ft-list-locations li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  display: inline-block;
  cursor: default
}

.ft-list-locations li {
  position: relative;
  margin: 0 0 10px;
  padding-left: 5px;
}

.ft-list {
  margin: 0;
  padding: 0;
}

.ft-list li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  display: inline-block;
}

.ft-list li {
  position: relative;
  margin: 0 0 10px;
  padding-left: 28px;
}

.ft-list li::before {
  content: "";
  background-image: url("../../public/images/arrow-forward.svg");
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  background-size: cover;
  background-position: top 1px center;
}
.copyright {
  background-color: #10162e;
  text-align: center;
  padding: 20px 0;
}

.copyright p {
  color: #8dcbff;
  font-size: 14px;
  margin: 0;
  font-weight: 500;
}
/* End Footer */

/* Blog */
.cmn-hero {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 153px 0;
}

.cmn-hero-inner h2 {
  font-size: 40px;
}

ul.breadcrums {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

ul.breadcrums li a,
ul.breadcrums li {
  font-size: 16px;
  font-weight: 700;
}

ul.breadcrums li a {
  text-decoration: none;
  color: #ff6128;
  display: flex;
  align-items: center;
}

ul.breadcrums li {
  color: #8a909a;
  margin-right: 5px;
}

.col-med-3 {
  width: 33.3%;
  padding: 0 15px;
}

.blg-ls-img img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  display: block;
}
.blg-ls {
  border: 1px solid #dddddd;
  border-radius: 10px;
  transition: all 0.3s;
  background-color: #fff;
  overflow: hidden;
}

.blg-ls-content {
  padding: 25px 30px;
}

.auth-m {
  color: #8a909a;
  font-size: 12px;
  font-weight: 700;
}

.blg-ls-content h2 {
  font-size: 16px;
  margin: 8px 0;
}

.blg-ls-content p {
  font-size: 14px;
  line-height: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rdo-more {
  color: #ff6128;
  text-decoration: none;
  font-size: 14px;
}

.blog-listing .col-med-3 {
  margin: 15px 0;
}

.blg-ls:hover {
  box-shadow: 2px 5px 5px 0 rgb(0 0 0 / 9%);
}
.pagi_ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.pagi_ul li a {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #d9d9d9;
  line-height: 40px;
  text-align: center;
  border-radius: 50px;
  color: #000;
  font-size: 16px;
  text-decoration: none;
  margin: 0 5px;
}

.pagination {
  margin: 15px 0 0;
}
.pagi_ul li.active a {
  background-color: #0c518a;
  color: #fff;
}
/* End Blog */

/* Blog Details */
.blg-dtl-img {
  height: 390px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  margin: 0 0 30px;
}

.blog-details h2 {
  font-size: 36px;
}
.breadcrums-main {
  padding: 20px 0;
}

.blog-details {
  padding: 0 0 70px;
}
/* End Blog Details */

/* Contact us */
.contact-form form {
  max-width: 920px;
  margin: 0 auto;
  display: block;
  width: 100%;
}

.half-wd {
  width: 50%;
  padding: 0 10px;
  margin: 0 0 20px;
}

.full-wd {
  width: 100%;
  padding: 0 10px;
  margin: 0 0 20px;
}

.sbt-fm-btn {
  background-color: #0c518a;
  border: none;
  color: #fff;
  padding: 16px 58px;
  font-size: 15px;
  font-weight: 500;
  font-family: "Nunito Sans", sans-serif;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

textarea.ct-field {
  height: 130px;
  resize: none;
}

.contact-form {
  margin: 10px 0 0;
  display: inline-block;
  width: 100%;
}
.ctt-dtl-inner {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.15);
  padding: 40px 40px 30px;
  margin-bottom: -100px;
  position: relative;
  z-index: 2;
}

.col-med-4 {
  width: 33.3%;
  padding: 0 15px;
}

.ctt_icon {
  display: inline-block;
  width: 65px;
  height: 65px;
  background-color: #0c518a;
  text-align: center;
  line-height: 88px;
  border-radius: 50px;
}

.ctt_icon svg {
  width: 35px;
  height: 35px;
}

.ctt_text p {
  color: #8a909a;
  font-size: 15px;
  margin: 12px 0 2px;
}

.ct_p,
.ct_p a {
  color: #0c518a;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
}

.map-section {
  margin: 0 0 60px;
}
/* End Contact us */

/* About us */
.cmn-left h2 {
  font-size: 40px;
}
.grey-bg {
  background-color: #f7f8f9;
}
.ab-txt p {
  margin: 0;
  color: #000;
  font-weight: 700;
}

.abt_opt_fx {
  align-items: center;
  width: 50%;
}

.ab-txt {
  padding-left: 10px;
  width: calc(100% - 45px);
}

.ab-ic {
  width: 45px;
}

.ab_list {
  margin: 0;
  padding: 0;
}

.ab_list li {
  position: relative;
  padding-left: 28px;
  color: #8a909a;
  font-weight: 700;
  margin: 10px 0;
}

.ab_list li::before {
  content: "";
  background-image: url("../../public/images/radio_checked.svg");
  width: 20px;
  height: 20px;
  position: absolute;
  background-size: cover;
  left: 0;
}
/* End About us */

/* Hero section video styles start */
.video-container {
  position: relative;
  aspect-ratio: 16/9;
  overflow: hidden;
  margin-bottom: 25px;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* Video styles end */

/* About page styles */

.outer-container {
  width: 100%;
  max-width: 1155px;
  margin: 0 auto;
  position: relative;
  background-color: #0c518a;
  border-radius: 32px;
}

.inner-container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-left: 75px;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section_brd span {
  height: 6px;
  border-radius: 50px;
  background-color: #ff6128;
  display: inline-block;
  margin: 0 5px;
}

.first_brd {
  width: 16px;
}
.second_brd {
  width: 46px;
}

.image {
  flex-shrink: 0;
  transform: translate(100px, 30px);
  margin-top: 10px;
  width: 40%;
}

.image img {
  width: 100%;
}

.outer-container-2 {
  width: 100%;
  max-width: 1155px;
  margin: 0 auto;
  position: relative;
  background-color: #0c518a;
  border-radius: 32px;
}

.inner-container-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-left: 75px;
}

.content-2 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section_brd span {
  height: 6px;
  border-radius: 50px;
  background-color: #ff6128;
  display: inline-block;
  margin: 0 5px;
}

.first_brd {
  width: 16px;
}
.second_brd {
  width: 46px;
}

.image-2 {
  flex-shrink: 0;
  transform: translate(100px, 0px);
  margin-top: 10px;
  width: 40%;
}

.image-2 img {
  width: 100%;
}

.abt_section_3 {
  width: 100vw;
  min-height: 506px;
  background: #0c518a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

/* About page styles end */


.error-occured {
  border: 2px solid red;
}

@media (min-width: 767px) {
  .outer-container {
    width: 100%;
    max-width: 1155px;
    margin: 0 auto;
    position: relative;
    background-color: #0c518a;
    border-radius: 32px;
  }

  .outer-container-2 {
    width: 100%;
    max-width: 1155px;
    margin: 0 auto;
    position: relative;
    background-color: #0c518a;
    border-radius: 32px;
  }
}

@media only screen and (max-width: 767px) {
  /* Header */
  .toggle-btn {
    display: block;
    margin-left: auto;
  }
  .menu-items {
    display: none;
  }
  .logo img {
    max-width: 130px;
  }
  .menu-items.show-menu {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    max-width: 250px;
    width: 100%;
    background-color: rgb(0 56 103 / 90%);
    padding: 20px 15px;
    top: 0;
    height: calc(100vh - 50px);
    box-shadow: 1px 0 7px 0 rgb(0 0 0 / 22%);
    z-index: 200;
  }

  .main-menu li {
    width: 100%;
    margin: 0;
  }

  .middle-header {
    position: relative;
  }

  .tp-left > p {
    display: none;
  }

  .tp-social-link {
    border-left: none;
    margin: 0;
    padding: 0;
  }

  .main-menu li a {
    display: inline-block;
    width: 100%;
    padding: 10px 0;
  }

  .tp-social-link li a {
    margin: 0 10px 0 0px;
  }
  /* End Header */
  .hero-inner h1 {
    font-size: 36px;
  }

  .hero-section {
    padding: 50px 0 110px;
  }

  .btn {
    font-size: 15px;
    padding: 14px 30px;
  }

  .btn svg {
    width: 20px;
    margin-left: 5px;
    height: 20px;
  }

  .col-md-6 {
    width: 100%;
  }

  .auto-left h2 {
    font-size: 32px;
  }

  .auto-form {
    max-width: 100%;
    margin-top: 50px;
  }

  .landing-bx h3 {
    font-size: 18px;
  }

  .landing-bx {
    max-width: 470px;
    padding: 15px 20px;
    border-radius: 10px;
  }

  .head-cmn h2 {
    font-size: 32px;
  }
  .counter-txt h3 {
    font-size: 22px;
  }

  .counter-txt p {
    font-size: 16px;
  }
  .cmn-padd {
    padding: 60px 0;
  }

  .col-md-5 {
    width: 100%;
  }

  .col-md-7 {
    width: 100%;
  }

  .pd-left {
    padding-left: 0;
  }

  .cmn-left.pd-left {
    margin-top: 20px;
  }

  .tp-wave::before {
    height: 60px;
  }

  .btm-wave::after {
    height: 50px;
  }

  .pd-right {
    padding-right: 0;
  }

  .section-three {
    padding: 100px 0;
  }

  .col-md-3 {
    width: 50%;
    margin: 10px 0;
  }
  .column-reversed-mb {
    flex-direction: column-reverse;
  }
  .sponser-wd {
    width: 33.33%;
    text-align: center;
    margin: 5px 0;
  }

  .sponser-main .row-flex {
    justify-content: center;
  }

  .ftr-one-wd {
    width: 100% !important;
  }

  .ftr-cmn-wd {
    width: 50%;
    margin: 0 0 30px;
  }

  .ftr-social h4 {
    font-size: 20px;
  }

  .ftr-bx h2 {
    font-size: 20px;
  }

  .ft-list li a {
    font-size: 14px;
  }

  .ft-list li {
    margin: 0 0 5px;
  }

  .footer {
    padding: 80px 0 20px;
  }

  .newsletter-main {
    padding: 25px;
  }

  .news-left h3 {
    font-size: 26px;
    margin: 0 0 10px;
  }
  .app-logo img {
    width: 100%;
    max-width: 110px;
  }
  .news-left p {
    font-size: 15px;
    line-height: 22px;
  }
  /* Blog */
  .cmn-hero {
    padding: 100px 0;
  }

  .cmn-hero-inner h2 {
    font-size: 32px;
    margin: 0 0 5px;
  }

  ul.breadcrums li a,
  ul.breadcrums li {
    font-size: 14px;
  }

  ul.breadcrums li a svg {
    width: 20px;
    height: 20px;
  }
  .col-med-3 {
    width: 100%;
  }
  /* End Blog */

  /* Blog Details */
  .blg-dtl-img {
    height: 250px;
  }

  .blog-details h2 {
    font-size: 28px;
  }
  /* End Blog Details */

  /* Contact us */
  .half-wd {
    width: 100%;
    margin: 0 0 10px;
  }

  .col-med-4 {
    width: 100%;
  }

  .ctt-dtl-inner .col-med-4 {
    margin: 20px 0;
  }

  .ctt-dtl-inner {
    padding: 25px 25px 20px;
  }

  .ctt_box {
    text-align: center;
  }
  /* End Contact us */

  /* About us */
  .cmn-left h2 {
    font-size: 32px;
  }

  .cmn-left .cmn-brd {
    margin: 15px 0;
  }

  .abt_opt_fx {
    width: 100%;
    margin: 5px 0;
  }

  .ab-ic {
    width: 35px;
  }

  .ab-ic svg {
    width: 35px;
    height: 35px;
  }
  /* End About us */

  .inner-container {
    flex-direction: column-reverse;
    align-items: stretch;
    margin-left: 25px;
    margin-right: 25px;
  }

  .content {
    margin-bottom: 10px;
  }

  .image {
    margin-right: 0;
    width: 100%;
    margin-bottom: 10px;
    transform: translate(0px, 0px);
    margin-top: -50px;
  }

  .inner-container-2 {
    flex-direction: column-reverse;
    align-items: stretch;
    margin-left: 25px;
    margin-right: 25px;
  }

  .content-2 {
    margin-bottom: 10px;
  }

  .image-2 {
    margin-right: 0;
    width: 100%;
    margin-bottom: 10px;
    transform: translate(0px, 0px);
    margin-top: -50px;
  }
}

@media only screen and (max-width: 480px) {
  .col-md-3 {
    width: 100%;
  }
  .counter-txt h3 {
    font-size: 22px;
  }

  .counter-txt p {
    font-size: 16px;
  }

  .col-md-3 {
    width: 100%;
  }

  .testimonial-vdo {
    width: 100%;
    margin: 0 0 20px;
  }

  .testimonial-content {
    width: 100%;
    padding: 0;
  }
  .sponser-wd {
    width: 50%;
  }
  .sponser-wd img {
    height: 50px;
    object-fit: contain;
    width: 100%;
  }
}
